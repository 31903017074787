import $ from 'jquery'
import 'css/user-survey.sass'
import Raty from 'raty-js'
import staron from 'raty-js/src/images/star-on.png'
import staroff from 'raty-js/src/images/star-off.png'
import Cookies from 'js-cookie'

var raty

function sendResponse(score, text) {
    $('#user-survey').hide()

    const resultSubmission = {
        // Note: this may be undefined if the close button is pressed with no selection.
        score: score,
        comment: text
    }

    $.post("/en/helpdesk/survey-result", resultSubmission, null, "json")

    if (Cookies.get('cookieconsent_status') === 'allow') {
        const delay = $('#user-survey').attr('data-delay')

        Cookies.set('survey_completed',
                    'true',
                    { expires: parseInt(delay),
                      path: '/',
                      secure: true,
                      sameSite: 'strict'})
    }
}

function starClicked(score) {
    if (score < 3) {
        $('#user-survey-textbox, #user-survey-textbox-submit, #user-survey-textbox-question').show()
        $('#user-survey-textbox').focus()
    } else {
        sendResponse(score, null)
    }
}

function closeClicked() {
    sendResponse(raty.score(), null)
}

function submitClicked() {
    sendResponse(raty.score(), $('#user-survey-textbox').val())
}

function isInSample() {
    const percentage = $('#user-survey').attr('data-proportion')
    return !Cookies.get('survey_completed') && Math.random() < (percentage / 100.0)
}

$(document).ready(function () {
    if (isInSample()) {
	raty = new Raty(
	    document.querySelector('#user-survey-rating'),
	    {starOn: staron,
             starOff: staroff,
             click: starClicked,
             hints: ['1', '2', '3', '4', '5']})
	raty.init()
        $('#user-survey-close').on('click', closeClicked)
        $('#user-survey-textbox-submit').on('click', submitClicked)
        $('#user-survey').show()
    }
})
