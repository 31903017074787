import 'bootstrap/scss/bootstrap.scss'
import 'css/tidy.sass'
import 'css/base.sass'
import 'css/innernav.sass' // Almost every page can potentially need this
import 'css/esa-header.sass'  // There is a possibility ESA will continue updating the header style
import 'cookieconsent/build/cookieconsent.min.css'

// This is disabled for now and we're using the kit.fontawesome.com link ESA use on their main site.
//
// TODO: We should get our own Pro licence and bundle the fonts like this.
// (Imagine what will happen if ESA change their site, stop maintaining their licence and it stops working).
//
// Once enabled this should bundle specifically the icons we use in to our distribution bundles
// and serve them from our site. Watch out that npm repo access would only last as long as our
// licence so we might have to download the module and add it to git.
//import './fontawesome'

// import 'googleanalytics.js'
import 'matomoanalytics.js'

import $ from 'jquery'
window.jQuery = window.$ = $ // Bootstrap requires this, otherwise it won't detect jquery

// The AddThis JS will dynamically loaded only in those pages where it is meant to appear.
// import 'addthisbuttons.js'
import 'popper.js'
import 'bootstrap'

import 'overlay-menu.js'
// import 'cci.js'
//import 'modernizr' // Do we really need this? If so, it seems more complicated than this.

// Loading translated text

import gettextjs from 'gettext.js'
// JS tanslation

window.i18n = new gettextjs()

//  Better to declare them manually than automatically searching
//  inside of the folders.
const esTrans = require('locale/es/messages.json');
const deTrans = require('locale/de/messages.json');
const frTrans = require('locale/nl/messages.json');
const nlTrans = require('locale/fr/messages.json');

//  To avoid WebPack complainign about unused variables, the files
//  had to be added one by one.
window.i18n.loadJSON(esTrans,'messages');
window.i18n.loadJSON(deTrans,'messages');
window.i18n.loadJSON(nlTrans,'messages');
window.i18n.loadJSON(frTrans,'messages');

import 'user-survey.js'
