import $ from 'jquery'
import 'css/overlay-menu.sass'

// This originally comes from code on esa.int
//
// Note that Isotope requires a commercial licence and we shouldn't use it without buying one.
// We no longer need it because it was required for the grid on the right-hand side of the page when the menu
// is open, which we have left empty and disabled.
/*const jQueryBridget = require('jquery-bridget')
const Isotope = require('isotope-layout')
// make Isotope a jQuery plugin
jQueryBridget('isotope', Isotope, $)*/

$(document).ready(function () {
    $('.esa-header #esa-menu').click(function () {
        $('#overlay-menu').addClass('active')
        $('body').addClass('noscroll')
    })

    const closeMenu = function closeMenu() {
        $('#overlay-menu').removeClass('active')
        $('#menu-content').scrollTop(0)
        $('body').removeClass('noscroll')
    }
    $('#overlay-menu .close-menu').click(closeMenu)
    $('#menu-content').click(closeMenu) // Right-hand panel use currently disabled
    // I don't know what this does, but the back-menu button is invisible/unused.
    /*$('#overlay-menu .back-menu').click(function () {
        $('#overlay-menu-wrapper').removeClass('slide')
        $('#sidebar-nav .menu-item').removeClass('active')
    })*/
    /*$('#sidebar-nav .menu-item[id]').click(function () {
        $('#overlay-menu-wrapper').addClass('slide')
        $('#sidebar-nav .menu-item').removeClass('active')
        $(this).addClass('active')
        $('#menu-content').scrollTop(0)
        const palias = '.' + this.id.substring(5) // menu_
        console.log(palias)
        $('#menu-content .theme-block' + palias).addClass('active')
        $('#menu-content .theme-block').not(palias).removeClass('active')
    })*/

    /*$('#overlay-menu .grid').isotope({
        itemSelector: '.grid-item',
        stagger: 50,
        masonry: {
            columnWidth: '.grid-sizer',
            gutter: 0
        }
    })*/

    // Appears unused
    /*$('.popmod #overlay-menu .popup').click(function () {
        const href = $(this).attr('href')
        $('#popup-modal').html('<iframe src="' + href + '"></iframe>')
        $('#popup-modal').addClass('active')
        return false
    })*/
})
