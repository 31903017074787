import Cookies from 'js-cookie'
import $ from 'jquery'

const html = $('html')
const is_preview = html.attr('data-is-preview') === "true"
const tracking_enabled = html.attr('data-matomo-enabled') === "true"

if (tracking_enabled && !is_preview && Cookies.get('cookieconsent_status') === 'allow') {
  /* eslint-disable no-undef */
  window._paq = window._paq || [];
  /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
  _paq.push(['trackPageView']);
  _paq.push(['enableLinkTracking']);
  (function() {
      var u=html.attr('data-matomo-url');
      console.log(u);
      _paq.push(['setTrackerUrl', u+'matomo.php']);
      _paq.push(['setSiteId', html.attr('data-matomo-site-id')]);
      var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
      g.type='text/javascript'; g.async=true; g.defer=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
  })();
  
  /* eslint-enable */
}
